@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @keyframes tmRotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes tmDash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
  
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}
